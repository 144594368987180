import React from 'react'

const TelegramIcon = ({ color }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.852 21.8013C18.1471 22.0065 18.5275 22.0578 18.8667 21.9318C19.2059 21.8049 19.4552 21.5205 19.5304 21.1758C20.3269 17.5001 22.2592 8.19684 22.9843 4.85333C23.0393 4.60133 22.9476 4.33943 22.746 4.17113C22.5443 4.00283 22.2647 3.95423 22.0154 4.04513C18.1719 5.44194 6.33524 9.80244 1.49719 11.5601C1.19011 11.6717 0.990282 11.9615 1.00036 12.2792C1.01136 12.5978 1.22953 12.8741 1.54394 12.9668C3.71364 13.604 6.56165 14.4905 6.56165 14.4905C6.56165 14.4905 7.89262 18.4371 8.58653 20.4441C8.67361 20.6961 8.87435 20.8941 9.13926 20.9625C9.40326 21.03 9.68558 20.9589 9.88266 20.7762C10.9973 19.743 12.7206 18.1454 12.7206 18.1454C12.7206 18.1454 15.9949 20.5026 17.852 21.8013ZM7.75971 13.9919L9.29876 18.9762L9.64067 15.8198C9.64067 15.8198 15.587 10.5539 18.9767 7.55244C19.0757 7.46424 19.0895 7.31664 19.007 7.21314C18.9254 7.10964 18.775 7.08534 18.6623 7.15554C14.7336 9.61884 7.75971 13.9919 7.75971 13.9919Z"
        fill={color}
      />
    </svg>
  )
}

export default TelegramIcon
