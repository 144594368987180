export default async () => [
  {
    poolName: 'BTCDAAAAI',
    poolPair: 'WWWWW/WWWWW',
    poolIcon: 'corn-icon.svg',
    poolUrl: 'https://armor.fi',
    yealdArmor: '0.312',
    rewardMultiplier: '3.14',
    roiDay: '0.07',
    roiMonth: '1.99',
    roiYear: '24.26',
    underlyingTokens: [
      {
        tokenName: 'WBTC',
        tokenBalance: '6,636.89',
        tokenIcon: 'wbtc.svg',
      },
      {
        tokenName: 'WBTC',
        tokenBalance: '6,636.89',
        tokenIcon: 'wbtc.svg',
      },
      {
        tokenName: 'WBTC',
        tokenBalance: '6,636.89',
        tokenIcon: 'wbtc.svg',
      },
      {
        tokenName: 'WBTC',
        tokenBalance: '6,636.89',
        tokenIcon: 'wbtc.svg',
      },
      {
        tokenName: 'WBTC',
        tokenBalance: '6,636.89',
        tokenIcon: 'wbtc.svg',
      },
      {
        tokenName: 'WBTC',
        tokenBalance: '6,636.89',
        tokenIcon: 'wbtc.svg',
      },
    ],
    balanceAvailable: '0',
    balanceStaked: '0',
    balancePair: 'WBTC-ETH SLP',
    earningsArmor: '0',
    isApproved: true,
  },
  {
    poolName: 'BitCorn',
    poolPair: 'WBTC/ETH',
    poolIcon: 'corn-icon.svg',
    poolUrl: 'https://armor.fi',
    yealdArmor: '0.312',
    rewardMultiplier: '3.14',
    roiDay: '0.07',
    roiMonth: '1.99',
    roiYear: '24.26',
    underlyingTokens: [
      {
        tokenName: 'WBTC',
        tokenBalance: '6,636.89',
        tokenIcon: 'wbtc.svg',
      },
      {
        tokenName: 'WBTC',
        tokenBalance: '6,636.89',
        tokenIcon: 'wbtc.svg',
      },
      {
        tokenName: 'WBTC',
        tokenBalance: '6,636.89',
        tokenIcon: 'wbtc.svg',
      },
    ],
    balanceAvailable: '0',
    balanceStaked: '0',
    balancePair: 'WBTC-ETH SLP',
    earningsArmor: '0',
    isApproved: true,
  },
  {
    poolName: 'BitPorn',
    poolPair: 'WTFBTC/ETH',
    poolIcon: 'eth.png',
    poolUrl: 'https://armor.fi',
    yealdArmor: '0.312',
    rewardMultiplier: '3.14',
    roiDay: '0.07',
    roiMonth: '1.99',
    roiYear: '24.26',
    underlyingTokens: [
      {
        tokenName: 'WBTC',
        tokenBalance: '6,636.89',
        tokenIcon: 'wbtc.svg',
      },
      {
        tokenName: 'WBTC',
        tokenBalance: '6,636.89',
        tokenIcon: 'wbtc.svg',
      },
      {
        tokenName: 'WBTC',
        tokenBalance: '6,636.89',
        tokenIcon: 'wbtc.svg',
      },
    ],
    balanceAvailable: '0',
    balanceStaked: '0',
    balancePair: 'WBTC-ETH SLP',
    earningsArmor: '0',
    isApproved: false,
  },
  {
    poolName: 'BitCorn',
    poolPair: 'WBTC/ETH',
    poolIcon: 'eth.png',
    poolUrl: 'https://armor.fi',
    yealdArmor: '0.312',
    rewardMultiplier: '3.14',
    roiDay: '0.07',
    roiMonth: '1.99',
    roiYear: '24.26',
    underlyingTokens: [
      {
        tokenName: 'WBTC',
        tokenBalance: '6,636.89',
        tokenIcon: 'wbtc.svg',
      },
      {
        tokenName: 'WBTC',
        tokenBalance: '6,636.89',
        tokenIcon: 'wbtc.svg',
      },
      {
        tokenName: 'WBTC',
        tokenBalance: '6,636.89',
        tokenIcon: 'wbtc.svg',
      },
    ],
    balanceAvailable: '0',
    balanceStaked: '0',
    balancePair: 'WBTC-ETH SLP',
    earningsArmor: '0',
    isApproved: false,
  },
  {
    poolName: 'BitCorn',
    poolPair: 'WBTC/ETH',
    poolIcon: 'eth.png',
    poolUrl: 'https://armor.fi',
    yealdArmor: '0.312',
    rewardMultiplier: '3.14',
    roiDay: '0.07',
    roiMonth: '1.99',
    roiYear: '24.26',
    underlyingTokens: [
      {
        tokenName: 'WBTC',
        tokenBalance: '6,636.89',
        tokenIcon: 'wbtc.svg',
      },
      {
        tokenName: 'WBTC',
        tokenBalance: '6,636.89',
        tokenIcon: 'wbtc.svg',
      },
      {
        tokenName: 'WBTC',
        tokenBalance: '6,636.89',
        tokenIcon: 'wbtc.svg',
      },
    ],
    balanceAvailable: '0',
    balanceStaked: '0',
    balancePair: 'WBTC-ETH SLP',
    earningsArmor: '0',
    isApproved: false,
  },
]
