import React from 'react'
import ArmorIcon from '../icons/ArmorIcon'
import {
  CardWrapper,
  BoxBalance,
  HeaderBalance,
  ValueBalance,
  BalanceButton,
  TotalValue,
} from './styled'
import { commas } from '../../helpers'

const Card = ({
  data,
  arnxmBalance,
  arcoreBalance,
  onWithdrawArCore,
  onWithdrawArNxm,
}) => (
  <CardWrapper>
    <BoxBalance>
      <HeaderBalance>arCore Balance</HeaderBalance>
      <ValueBalance>
        <span>{commas(2).format(arcoreBalance)} ETH</span>
      </ValueBalance>
      <BalanceButton disabled={arcoreBalance <= 0} onClick={onWithdrawArCore}>
        WITHDRAW
      </BalanceButton>
    </BoxBalance>

    <BoxBalance>
      <HeaderBalance>arNXM Balance</HeaderBalance>
      <ValueBalance>
        <span>{commas(2).format(arnxmBalance)} arNXM</span>
      </ValueBalance>
      <BalanceButton disabled={arnxmBalance <= 0} onClick={onWithdrawArNxm}>
        WITHDRAW
      </BalanceButton>
    </BoxBalance>
  </CardWrapper>
)

export default Card
