import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'

export const DropdownStyled = styled(TextField)`
  max-width: 285px;
  height: 40px;
  font-family: 'Open Sans', sans-serif;
  width: 100%;
  border-radius: 6px;
  border: 1px solid ${(p) => p.theme.colors.primaryLightTrue};
  background: transparent;
  font-family: 'Open Sans', sans-serif;
  color: ${(p) => p.theme.colors.secondary};
  & .MuiSelect-select:focus {
    border-radius: 6px;
  }
  & .MuiInput-underline {
    &:before {
      display: none;
      height: 0px;
      border-bottom: none;
    }
    &:after {
      display: none;
      height: 0px;
      border-bottom: none;
    }
    &:hover:not($disabled):before {
      display: none;
      height: 0px;
      border-bottom: none;
    }
  }
  & .MuiSelect-select {
    height: 40px;
    padding: 0 24px 0 9px;
    display: flex;
    align-items: center;
  }
  & .MuiInput-root {
    display: flex;
    align-items: center;
    height: 100%;
  }
  & .MuiSvgIcon-root {
    right: 7px;
    ${(p) => (p.hideArrow ? 'display: none;' : '')}
    color: ${(p) => p.theme.colors.strongDefault};
  }

  @media screen and (max-width: 448px) {
    margin-left: 0;
    max-width: 300px;
  }
`
export const SelectMenu = styled(MenuItem)`
  padding: 12px 12px 12px 15px;
  min-width: 200px;
`
export const SelectIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  height: 30px;
  max-width: 30px;
  width: 100%;
  min-width: 30px;
  border: 1px solid ${(p) => p.theme.colors.buttonActiveBg};
  background: ${(p) => p.theme.colors.secondary};

  & img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 15px;
  }

  @media screen and (max-width: 548px) {
    height: 20px;
    max-width: 20px;
    width: 100%;
    min-width: 20px;
  }
`
export const SelectIconName = styled.h4`
  padding-left: 10px;
  display: inline-block;
  vertical-align: middle;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  color: ${(p) => p.theme.colors.secondary};
`
export const Placeholder = styled.div`
  padding-left: 10px;
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  line-height: 22px;
  color: ${(p) => p.theme.colors.primaryDefault};
`
