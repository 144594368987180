import React from 'react'
import { withTheme } from 'styled-components'
import TooltipGuide from '../../common/tooltipGuide/TooltipGuide'
import AboutInfoIcon from '../../icons/AboutInfoIcon'
import TableHead from '@material-ui/core/TableHead'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import Paper from '@material-ui/core/Paper'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '../../common/button/Button'
import {
  Box,
  Header,
  Title,
  TooltipSpan,
  Logo,
  TableStyledWrapper,
  TableStyled,
  TableTitle,
  TableContainerStyled,
  Cell,
  ActionWrapper,
  CropSpan,
  AssetsName,
  AssetWrapper,
  AssetLogo,
  AssetsCell,
  AssetTitle,
  Row,
  EditButton,
  ActionCell,
  TooltipWrapper,
  TitleWrapper,
  TitleTooltipWrapper,
} from './styled'

const TableCard = ({
  theme,
  title,
  description,
  logo,
  pools,
  handleOpenMintModal,
  handleOpenRedeemModal,
}) => {
  const { colors } = theme

  return (
    <Box>
      <Header>
        <TitleWrapper>
          <Logo
            src={require(`../../../assets/${logo}`)}
            alt={`${title} logo`}
          />
          <Title>{title}</Title>
          <TooltipGuide text="Tooltip text">
            <TooltipSpan>
              <AboutInfoIcon color={colors.disabledText} />
            </TooltipSpan>
          </TooltipGuide>
        </TitleWrapper>

        <Button
          buttonText="Rewards"
          tooltipText="Rewards Tooltip"
          isDisabled={false}
          onClick={() => console.log('Rewards')}
          bordered={false}
          margin="0"
        />
      </Header>
      <TableContainerStyled component={Paper}>
        <TableStyledWrapper>
          <TableStyled aria-label="simple table">
            <TableHead>
              <TableRow>
                <AssetTitle>Token</AssetTitle>
                <TableTitle align="right">arBalance</TableTitle>
                <TableTitle align="right">
                  {title[0].toLowerCase()}Balance
                </TableTitle>
                <TableTitle align="right">APY</TableTitle>
                <TableTitle align="right">Cost</TableTitle>
                <TableTitle align="right">
                  <TitleTooltipWrapper>
                    Coverage: $50MM/280MM
                    <TooltipGuide text="Tooltip Coverage">
                      <TooltipSpan>
                        <AboutInfoIcon color={colors.disabledText} />
                      </TooltipSpan>
                    </TooltipGuide>
                  </TitleTooltipWrapper>
                </TableTitle>
              </TableRow>
            </TableHead>
            <TableBody>
              {pools.map(
                (
                  {
                    title,
                    address,
                    logo,
                    arBalance,
                    protocolBalance,
                    apy,
                    cost,
                    coverage,
                  },
                  index
                ) => (
                  <Row key={index}>
                    <AssetsCell>
                      <Tooltip
                        arrow
                        placement="top"
                        enterTouchDelay={50}
                        title={title}
                      >
                        <AssetWrapper
                          onClick={() =>
                            window.open(
                              `https://etherscan.io/address/${address}`,
                              '_blank'
                            )
                          }
                        >
                          <AssetLogo
                            src={require(`../../../assets/${logo}`)}
                            alt={`${title} icon`}
                          />
                          <AssetsName>
                            <CropSpan>{title}</CropSpan>
                          </AssetsName>
                        </AssetWrapper>
                      </Tooltip>
                    </AssetsCell>
                    <Tooltip
                      arrow
                      placement="top"
                      enterTouchDelay={50}
                      title={`${arBalance} ${title}`}
                    >
                      <Cell align="right">{arBalance}</Cell>
                    </Tooltip>
                    <Tooltip
                      arrow
                      placement="top"
                      enterTouchDelay={50}
                      title={`${protocolBalance} ${title}`}
                    >
                      <Cell align="right">{protocolBalance}</Cell>
                    </Tooltip>
                    <Cell align="right">{apy}%</Cell>
                    <Cell normal align="right">
                      {cost}%
                    </Cell>
                    <ActionCell align="right">
                      <ActionWrapper>
                        <Button
                          buttonText="mint"
                          tooltipText="mint"
                          isDisabled={false}
                          onClick={handleOpenMintModal}
                          bordered={true}
                          margin="0 7px"
                        />
                        <Button
                          buttonText="redeem"
                          tooltipText="redeem"
                          isDisabled={false}
                          onClick={handleOpenRedeemModal}
                          bordered={true}
                          margin="0 7px"
                        />
                      </ActionWrapper>
                    </ActionCell>
                  </Row>
                )
              )}
            </TableBody>
          </TableStyled>
        </TableStyledWrapper>
      </TableContainerStyled>
    </Box>
  )
}

export default withTheme(TableCard)
