import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import {
  ERROR,
  CONNECTION_CONNECTED,
  CONNECTION_DISCONNECTED,
  REFERRER_SET,
  GET_REFERRAL_CODE,
  REFERRAL_CODE_RETURNED,
} from '../../constants'
import {
  TableContainerStyled,
  RowHead,
  TableTitle,
  Row,
  Cell,
  ShowMoreRow,
  ReferBox,
  Wrapper,
  UserAccount,
  LinkCell,
  ReferralURLWrapper,
  ReferFullBox,
  AddressTitle,
  AddressLink,
  InfoText,
  CopyButton,
  CropValue,
  FlexSpan,
} from './styled'
import Store from '../../stores/store'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import Card from './Card'
import MOCK_DATA from './mock'
import ReferralsSkeleton from './ReferralsSkeleton'
import ConnectWallet from '../common/connectWallet/ConnectWallet'
import {
  commas,
  removeEmitterListeners,
  turnOnEmitterListeners,
  uglifyAddress,
} from '../../helpers'
import { ReferralRewardsEvents } from '../../stores/contracts/referralRewardsEvents'
import { RewardManagerEvents } from '../../stores/contracts/rewardManagerEvents'
import { StakeManagerEvents } from '../../stores/contracts/stakeManagerEvents'
import { ArNFTEvents } from '../../stores/contracts/arNFTEvents'
import { BalanceManagerEvents } from '../../stores/contracts/balanceManagerEvents'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Tooltip from '@material-ui/core/Tooltip'
import TableBody from '@material-ui/core/TableBody'
import ReferralSystem from '../../classes/referralSystem'
import config from '../../config'
import Container from '../common/container/Container'
import { Title } from '../common/Title'
import { SubTitle } from '../common/SubTitle'

const emitter = Store.emitter
const dispatcher = Store.dispatcher
const store = Store.store

const TOOLTIP_DISAPPEAR_DELAY = 1000

const Referrals = ({ t }) => {
  const [account, setAccount] = useState(null)
  const [referralCode, setReferralCode] = useState(null)
  const [showReferred, setShowReferred] = useState(false)
  const [showRecent, setShowRecent] = useState(false)
  const [referralRewardsEarned, setReferralRewardsEarned] = useState('0')
  const [balanceManagerBalance, setBalanceManagerBalance] = useState('0')
  const [referredUsers, setReferredUsers] = useState([])
  const [recentRewards, setRecentRewards] = useState([])
  const [withdrawals, setWithdrawals] = useState([])
  const [isCopied, setIsCopied] = useState(false)
  const balance = MOCK_DATA().balance

  useEffect(() => {
    const _account = store.getStore('account')

    if (_account) {
      setAccount(_account)
      dispatchUpdateEvents()
      dispatcher.dispatch({
        type: GET_REFERRAL_CODE,
        content: {
          address: _account.address,
        },
      })
    }

    let events = [
      [ERROR, errorReturned],
      [CONNECTION_DISCONNECTED, connectionDisconnected],
      [CONNECTION_CONNECTED, connectionConnected],
      [REFERRER_SET, referrerSet],
      [REFERRAL_CODE_RETURNED, referralCodeReturned],
      [ReferralRewardsEvents.EarnedReturned, earnedReturned],
      [ReferralRewardsEvents.ReferredUsersReturned, referredUsersReturned],
      [ReferralRewardsEvents.RewardsPaidReturned, rewardsPaidReturned],
      [ReferralRewardsEvents.WithdrawalsReturned, withdrawalsReturned],
      [BalanceManagerEvents.BalanceReturned, balanceManagerBalanceReturned],
      [RewardManagerEvents.RewardCompleted, getRewardCompleted],
      [ReferralRewardsEvents.RewardCompleted, getRewardCompleted],
    ]

    turnOnEmitterListeners(emitter, events)

    return () => {
      removeEmitterListeners(emitter, events)
    }
  }, [account])

  function dispatchUpdateEvents() {
    dispatcher.dispatch({
      type: ReferralRewardsEvents.GetEarned,
      content: {},
    })
    dispatcher.dispatch({
      type: ReferralRewardsEvents.GetReferredUsers,
      content: {},
    })
    dispatcher.dispatch({
      type: ReferralRewardsEvents.GetRewardsPaid,
      content: {},
    })
    dispatcher.dispatch({
      type: ReferralRewardsEvents.GetWithdrawals,
      content: {},
    })
    dispatcher.dispatch({
      type: BalanceManagerEvents.GetBalance,
      content: {},
    })
  }

  const connectionConnected = () => {
    const _account = store.getStore('account')
    setAccount(_account)
  }

  const connectionDisconnected = () => setAccount(null)

  const errorReturned = (e) => {
    console.log({ e })
  }

  const referrerSet = (r) => {
    //console.log({ r })
  }

  const referralCodeReturned = (r) => {
    const _code = store.getStore('referralCode')
    setReferralCode(_code)
  }

  const earnedReturned = () => {
    const _earned = store.getStore('ReferralRewards_Earned')
    setReferralRewardsEarned(_earned)
  }

  const referredUsersReturned = () => {
    const _users = store.getStore('ReferralRewards_ReferredUsers')
    setReferredUsers(_users)
  }

  const rewardsPaidReturned = () => {
    const _rewards = store.getStore('ReferralRewards_RewardsPaid')
    setRecentRewards(_rewards)
  }

  const balanceManagerBalanceReturned = () => {
    const _balance = store.getStore('BalanceManager_Balance')
    setBalanceManagerBalance(_balance)
  }

  const getRewardCompleted = () => {
    dispatchUpdateEvents()
  }

  const withdrawalsReturned = () => {
    const _withdrawals = store.getStore('ReferralRewards_Withdrawals')
    setWithdrawals(_withdrawals)
  }

  const handleToggleReffered = () => {
    setReferredUsers(MOCK_DATA().referredUsers)
    setShowReferred(!showReferred)
  }

  const handleToggleRecent = () => {
    setRecentRewards(MOCK_DATA().recentRewards)
    setShowRecent(!showRecent)
  }

  const onWithdrawArNxm = () => {
    dispatcher.dispatch({
      type: ReferralRewardsEvents.GetReward,
      content: {},
    })
  }

  const onWithdrawArCore = () => {
    dispatcher.dispatch({
      type: RewardManagerEvents.GetReward,
      content: {},
    })
  }

  const onCopied = () => {
    setIsCopied(true)
    setTimeout(() => {
      setIsCopied(false)
    }, TOOLTIP_DISAPPEAR_DELAY)
  }

  return (
    <Container noaccount={!account}>
      <Title>{t('Referrals.Title')}</Title>
      {account ? (
        <>
          <Card
            data={balance}
            arnxmBalance={referralRewardsEarned || '0'}
            arcoreBalance={balanceManagerBalance || '0'}
            onWithdrawArNxm={onWithdrawArNxm}
            onWithdrawArCore={onWithdrawArCore}
          />
          <ReferralURLWrapper>
            <AddressTitle>{t('Referrals.ReferralAddress')}:</AddressTitle>
            <AddressLink>
              https://armor.fi/r/{referralCode}
              <CopyToClipboard
                text={`https://armor.fi/r/${referralCode}`}
                onCopy={onCopied}
              >
                <Tooltip
                  arrow
                  interactive
                  placement="top"
                  enterTouchDelay={50}
                  title="Copied"
                  open={isCopied}
                >
                  <CopyButton color="primary">Copy</CopyButton>
                </Tooltip>
              </CopyToClipboard>
            </AddressLink>
          </ReferralURLWrapper>
          <Wrapper>
            <ReferBox>
              <SubTitle>{t('Referrals.ReferredUsers')}</SubTitle>
              <TableContainerStyled component={Paper}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <RowHead>
                      <TableTitle align="left">
                        {t('Referrals.User')}
                      </TableTitle>
                      <TableTitle align="left">
                        {t('Referrals.Time')}
                      </TableTitle>
                    </RowHead>
                  </TableHead>
                  <TableBody>
                    {referredUsers.map(({ address, timestamp }, i) => (
                      <Row key={i} tabIndex={-1}>
                        <LinkCell align="left">
                          <UserAccount href="#">
                            {uglifyAddress(address, 10, 4)}
                          </UserAccount>
                        </LinkCell>
                        <Cell align="left">{timestamp}</Cell>
                      </Row>
                    ))}
                  </TableBody>
                </Table>
                {referredUsers.length <= 0 && (
                  <ShowMoreRow>
                    <InfoText>There is nothing to show yet</InfoText>
                  </ShowMoreRow>
                )}
              </TableContainerStyled>
            </ReferBox>
            <ReferBox>
              <SubTitle>Recent Rewards</SubTitle>
              <TableContainerStyled component={Paper}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <RowHead>
                      <TableTitle align="left">
                        {t('Referrals.User')}
                      </TableTitle>
                      <TableTitle align="left">
                        {t('Referrals.Protocol')}
                      </TableTitle>
                      <TableTitle align="left">
                        {t('Referrals.Amount')}
                      </TableTitle>
                      <TableTitle align="left">Time</TableTitle>
                    </RowHead>
                  </TableHead>
                  <TableBody>
                    {recentRewards.map(
                      ({ address, reward, protocol, timestamp }, i) => (
                        <Row key={i} tabIndex={-1}>
                          <LinkCell align="left">
                            <UserAccount href="#">
                              {uglifyAddress(address, 5, 4)}
                            </UserAccount>
                          </LinkCell>
                          <Cell align="left">{protocol}</Cell>
                          <Cell align="left">
                            <Tooltip
                              arrow
                              placement="top"
                              enterTouchDelay={50}
                              title={`${commas(4).format(reward)} ARMOR`}
                            >
                              <FlexSpan>
                                <CropValue>
                                  {commas(4).format(reward)}
                                </CropValue>
                                ARMOR
                              </FlexSpan>
                            </Tooltip>
                          </Cell>
                          <Cell align="left">{timestamp}</Cell>
                        </Row>
                      )
                    )}
                  </TableBody>
                </Table>
                {recentRewards.length <= 0 && (
                  <ShowMoreRow>
                    <InfoText>There is nothing to show yet</InfoText>
                  </ShowMoreRow>
                )}
              </TableContainerStyled>
            </ReferBox>
          </Wrapper>
          <Wrapper>
            <ReferFullBox>
              <SubTitle>Withdraws</SubTitle>
              <TableContainerStyled component={Paper}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <RowHead>
                      <TableTitle align="left">Protocol</TableTitle>
                      <TableTitle align="left">Amount</TableTitle>
                    </RowHead>
                  </TableHead>
                  <TableBody>
                    {withdrawals.map(({ protocol, amount }, i) => (
                      <Row key={i} tabIndex={-1}>
                        <Cell align="left">
                          <div style={{ paddingLeft: 14 }}>{protocol}</div>
                        </Cell>
                        <Cell align="left">
                          {commas(8).format(amount)} ARMOR
                        </Cell>
                      </Row>
                    ))}
                  </TableBody>
                </Table>

                {withdrawals.length <= 0 && (
                  <ShowMoreRow>
                    <InfoText>There is nothing to show yet</InfoText>
                  </ShowMoreRow>
                )}
              </TableContainerStyled>
            </ReferFullBox>
          </Wrapper>
        </>
      ) : (
        <>
          <ConnectWallet />
          <ReferralsSkeleton />
        </>
      )}
    </Container>
  )
}

export default withTranslation()(withRouter(Referrals))
