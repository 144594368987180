import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { removeEmitterListeners, turnOnEmitterListeners } from '../../helpers'
import Store from '../../stores/store'
import {
  CONNECTION_CONNECTED,
  CONNECTION_DISCONNECTED,
  ERROR,
} from '../../constants'
import { Description, FilterWrapper } from './styled'
import Search from '../common/search/Search'
import TableCard from './tableCard/TableCard'
import MintModal from './mintModal/MintModal'
import RedeemModal from './redeemModal/RedeemModal'
import Container from '../common/container/Container'
import { Title } from '../common/Title'

const store = Store.store
const emitter = Store.emitter
const dispatcher = Store.dispatcher

const ArShield = ({ t, network }) => {
  const [search, setSearch] = useState('')
  const [searchError, setSearchError] = useState(false)
  const [account, setAccount] = useState(null)
  const [isMintModalOpened, setIsMintModalOpened] = useState(false)
  const [isRedeemModalOpened, setIsRedeemModalOpened] = useState(false)

  useEffect(() => {
    const _account = store.getStore('account')
    setAccount(_account)

    let events = [
      [ERROR, errorReturned],
      [CONNECTION_CONNECTED, connectionConnected],
      [CONNECTION_DISCONNECTED, connectionDisconnected],
    ]
    turnOnEmitterListeners(emitter, events)

    return () => {
      removeEmitterListeners(emitter, events)
    }
  }, [network])

  const connectionConnected = async () => {
    const _account = store.getStore('account')
    setAccount(_account)
  }

  const connectionDisconnected = () => setAccount(null)

  const errorReturned = (error) => {
    console.log('Error', error)
  }

  const onSearchChanged = (e) => {
    setSearch(e.target.value)
  }

  const handleOpenMintModal = () => {
    setIsMintModalOpened(true)
  }

  const handleCloseMintModal = () => {
    setIsMintModalOpened(false)
  }
  const handleOpenRedeemModal = () => {
    setIsRedeemModalOpened(true)
  }

  const handleCloseRedeemModal = () => {
    setIsRedeemModalOpened(false)
  }

  const data = [
    {
      title: 'Yearn',
      logo: 'yearnfinance.png',
      pools: [
        {
          title: 'dai',
          address: '0x5c69bee701ef814a2b6a3edd4b1652cb9cc5aa6f',
          logo: 'dai.png',
          arBalance: '534134.93',
          protocolBalance: '12321.342',
          apy: '8.9',
          cost: '130.25',
          coverage: '89',
        },
        {
          title: 'MakerDAO',
          address: '0x5c69bee701ef814a2b6a3edd4b1652cb9cc5aa6f',
          logo: 'makerdao.png',
          arBalance: '43324.56',
          protocolBalance: '123436.7',
          apy: '526',
          cost: '251.2',
          coverage: '10',
        },
      ],
    },
    {
      title: 'Uniswap',
      logo: 'uniswap-logo.png',
      pools: [
        {
          title: 'UniswapUniswap',
          address: '0x5c69bee701ef814a2b6a3edd4b1652cb9cc5aa6f',
          logo: 'dai.png',
          arBalance: '53242',
          protocolBalance: '634324324',
          apy: '326',
          cost: '122.5',
          coverage: '50',
        },
        {
          title: 'SushiSwap',
          address: '0x5c69bee701ef814a2b6a3edd4b1652cb9cc5aa6f',
          logo: 'sushiswap.jpg',
          arBalance: '4434324',
          protocolBalance: '734325',
          apy: '36',
          cost: '29',
          coverage: '100',
        },
      ],
    },
  ]

  return (
    <Container noaccount={!account}>
      <Title>arShield</Title>
      <Description blur={isMintModalOpened || isRedeemModalOpened}>
        ArShield Armored Shield Vaults are covered storage vaults for LP tokens
        with autopayments, powered by arCore. Users can deposit their LP tokens
        and enjoy fully or partially collateralized smart contract coverage.
      </Description>
      {isMintModalOpened || isRedeemModalOpened ? (
        <>
          {isMintModalOpened && (
            <MintModal handleCloseModal={handleCloseMintModal} />
          )}
          {isRedeemModalOpened && (
            <RedeemModal handleCloseModal={handleCloseRedeemModal} />
          )}
        </>
      ) : (
        <>
          <FilterWrapper>
            <Search
              disabled={false}
              value={search}
              error={searchError}
              onChange={onSearchChanged}
              placeholder="Yearn, Uniswap..."
            />
          </FilterWrapper>
          {data.map(({ title, logo, pools }, index) => (
            <TableCard
              key={index}
              title={title}
              logo={logo}
              pools={pools}
              handleOpenMintModal={handleOpenMintModal}
              handleOpenRedeemModal={handleOpenRedeemModal}
            />
          ))}
        </>
      )}
    </Container>
  )
}

export default withTranslation()(withRouter(ArShield))
